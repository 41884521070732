.selectSeats {
    position: relative;
    height: 100%;

    &.open>.dropdown-menu {
        display: block;
    }

    .dropdown-toggle {
        display: inline-block;
        padding: 6px 6px;
        margin-bottom: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        width: 75px;
        font-size: 0.7rem;
        height: 35px;
        text-align: left;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        color: #444444;
        letter-spacing: 0.5px;
        border-radius: 0.5rem;
        border: 2px solid #444444;
    }

    .dropdown-toggle::after {
        display: inline-block;
        position: absolute;
        // right: 1px;
        top: 15px;
        left: 50px;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        z-index: 1000;
        float: left;
        width: 150px;
        padding: 5px 0;
        margin: 5px 0 0;
        font-size: 0.75rem;
        text-align: left;
        list-style: none;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        height: auto;

        li {
            cursor: pointer;

            :hover {
                background: #ddd;
            }

            &>p {
                display: block;
                padding: 5px 20px;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
                text-decoration: none;
            }
        }
    }
}