/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.text-login {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 1.7;
  color: #888888fd;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}


/*---------------------------------------------*/

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #555555;
}

input:-moz-placeholder {
  color: #555555;
}

input::-moz-placeholder {
  color: #555555;
}

input:-ms-input-placeholder {
  color: #555555;
}

textarea::-webkit-input-placeholder {
  color: #555555;
}

textarea:-moz-placeholder {
  color: #555555;
}

textarea::-moz-placeholder {
  color: #555555;
}

textarea:-ms-input-placeholder {
  color: #555555;
}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background-color: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #e5e5e5;
  line-height: 1.5;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom left, #0083b1, #01b4dc)
}

.wrap-login100 {
  width: 445px;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
}

.login100-form-logo {
  font-size: 60px;
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  font-family: Ubuntu-Bold;
  font-size: 28px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

/*------------------------------------------------------------------
[ link ] */
.link-control1:link {
  padding-left: 36%;
  font-family: Ubuntu-Regular;
  font-size: 15px;
  color: #01b3db;
}

/* visited link */
.link-control1:visited {
  color: #01b3db;
}

/* mouse over link */
.link-control1:hover {
  color: #555555;
}

/* selected link */
.link-control1:active {
  color: #fa4299;
}

.link-control2:link {
  /* padding-left: 10%; */
  font-family: Ubuntu-Regular;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10%;
  color: #ffffff;
}

/* visited link */
.link-control2:visited {
  color: #ffffff;
}

/* mouse over link */
.link-control2:hover {
  color: #555555;
}

/* selected link */
.link-control2:active {
  color: #fa4299;
}



/*------------------------------------------------------------------
[ Input ]*/
.input-control {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 3px solid #ccc;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.input-control:focus {
  font-family: Ubuntu-Regular;
  color: #495057;
  background-color: #fff;
  border-color: #01a8d2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 174, 255, 0.25);
}

.input-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.input-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.wrap-input100 {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}

.wrap-input50 {
  width: 100%;
  padding: 29px 0;
  display: block;
}

.input100 {
  font-family: Ubuntu-Regular;
  font-size: 20px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 50px;
  background-color: transparent;
  padding: 0 10px 0 80px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  /* background: #d41872;
  background: -webkit-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: -o-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: -moz-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: linear-gradient(left, #a445b2, #d41872, #fa4299); */
}

.focus-input100::after {
  font-family: Linearicons-Free;
  font-size: 18px;
  color: #999999;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 35px;


  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 60px;
}

.input100:focus+.focus-input100::after {
  left: 23px;
  color: #d41872;
}

.input100:focus+.focus-input100::before {
  width: 100%;
}

.has-val.input100+.focus-input100::after {
  left: 23px;
  color: #d41872;
}

.has-val.input100+.focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 60px;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 15%;
}

.login100-form-btn {
  font-family: Ubuntu-Bold;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  height: 42px;
  border-radius: 0.7rem;

  /* background: #00c4f4; */
  background: linear-gradient(to bottom left, #0083b1, #00a1cb, #00c4f4);
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
}

.login100-form-btn:after {
  content: "";
  background-color: whitesmoke;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.login100-form-btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
