table {
    font-family: Arial, Helvetica, sans-serif;
    color: #444444;
    font-size: .7rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    border-collapse: collapse;
    border-radius: 1rem;
    background: white;
    overflow: hidden;
    letter-spacing: 0.5px;
}

th {
    font-size: 1em;
    text-align: center;
}

thead {
    font-weight: bold;
    color: #fff;
    background: #73685d;
}

td,
th {
    padding: 1em .5em;
    vertical-align: middle;
}

td {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}

@media all and (max-width: 992px) {

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    th {
        text-align: right;
    }

    table {
        position: relative;
        padding-bottom: 0;
        border: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
    }

    thead {
        float: left;
        white-space: nowrap;
    }

    tbody {
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        white-space: nowrap;
    }

    tr {
        display: inline-block;
        vertical-align: top;
    }

    th {
        border-bottom: 1px solid #e5e5e5;
    }

    th:last-child {
        border-bottom: none;
    }

    td:last-child {
        border-bottom: none;
    }
}