/* text */
.text-product {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.55rem;
    display: inline;
}

.text-header-product {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    display: inline-block;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
}

.text-null {
    color: #00b4db;
}

.text-noData {
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #444444;
    justify-content: center;
    align-self: center;
}

.text-noData svg {
    font-size: 1.5rem;
    margin-left: 10px;
}

.boxSearchProduct {
    position: relative;
    height: auto;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
}

.headSearchProduct {
    position: relative;
    border-radius: 1rem 1rem 0rem 0rem;
    height: 40px;
    display: block;
}

.box-default {
    display: inline-block;
    position: relative;
}

.box-productlist {
    margin: 15px 0px 10px 0px;
    float: left;
}

.box-left {
    float: left;
    text-align: left;
    top: 30px;
    width: 50%;
}

.box-right {
    float: right;
    text-align: right;
    width: 50%;
}

.noData {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.posButton2 {
    float: right;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 13px;
}

.pagination {
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
}

.nav-pagination {
    display: block;
}

.select {
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 35px;
    height: 38px;
    border: none;
    box-shadow: 0 0 4px #ccc, 0 10px 15px #ebebeb inset;
    border: 1px solid #ddd;
    border-collapse: collapse;
    width: 100%;
}

.toolbox {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.toolbox input {
    width: 100%;
    height: 40px;
    border: 1px solid #ddd;
}

.toolbox input {
    text-indent: 32px;
}

.toolbox .toolbox-search {
    position: absolute;
    top: 7px;
    left: 10px;
    color: #777;
    font-size: 1rem;
}

.boxPickUp_DropOff {
    display: inline-table;
    position: relative;
    width: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    background-color: #ffffff;
}

.boxPickUp_DropOff ul {
    padding: 0;
}

.boxPickUp_DropOff input {
    width: 100%;
    height: 40px;
}

.boxPickUp_DropOff input {
    text-indent: 32px;
}

.boxSelectPickUp_DropOff {
    display: inline-block;
    max-width: 300px;
}

.boxDisplayNone {
    display: none;
}

.boxModalAlert {
    border-radius: 0.35rem;
}

.box-productName {
    display: grid;
}

/* button */
.button-product {
    position: relative;
    color: white;
    background: linear-gradient(45deg, #0083b0 30%, #009ed3 90%);
    box-shadow: 0 4px 5px rgba(6, 136, 153, 0.473);
    border-radius: 0.3rem;
    width: 90px;
    height: 35px;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    z-index: 200;
    cursor: pointer;
    letter-spacing: 1px;
}

.button-product:after {
    content: "";
    background: whitesmoke;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.button-product:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}

.button-card {
    position: relative;
    color: white;
    background: linear-gradient(45deg, #00b4db 30%, #02ccfa 90%);
    box-shadow: 0 4px 5px rgba(0, 146, 165, 0.418);
    border-radius: 0.3rem;
    width: 90px;
    height: 30px;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    z-index: 200;
    overflow: hidden;
    cursor: pointer;
    letter-spacing: 1px;
}

.button-card:after {
    content: "";
    background: whitesmoke;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.button-card:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}

/* input */
.input-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    /* margin-top: 5px; */
    /* margin-bottom: 10px; */
    box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.input-table>div {
    display: table-cell;
    border: 1px solid #ddd;
    vertical-align: middle;
    /* needed for Safari */
}

.input-table-icon {
    color: #777;
    padding: 0 12px
}

.input-table-area {
    border-radius: 1rem 0rem 1rem 0rem;
    width: auto;
}

.input-table input {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
}

/* product list -> icon & "produc tlsit" */

.text-product-head {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: left;
    display: inline;
    position: relative;
    color: #444444;
    letter-spacing: 0.5px;
}

.text-product-head svg {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
    display: inline;
    position: relative;
    color: #444444;
}

.text-pos-product-head {
    margin-right: 10px;
    margin-left: 15px;
}

.boxCreateBooking-DatePicker {
    width: 150px;
}

.input-date-createbooking {
    display: table;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.input-date-createbooking>div {
    display: table-cell;
    border: 1px solid #ddd;
    vertical-align: middle;
    height: 40px;
}

.input-date-createbooking input {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
    height: 39px;
    letter-spacing: 1px;
}

.input-search {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.input-search input {
    width: 100%;
    height: 37px;
    border: 1px solid #ddd;
    letter-spacing: 0.5px;
    text-indent: 35px;
}

.input-search .icons-search {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #777;
}

/* img */
.img_loading_data {
    top: 25%;
    left: 35%;
    z-index: 9999;
    position: absolute;
}

/* display */

@media (max-width: 480px) {
    .page-item-font {
        font-size: 10px;
    }
}

@media (max-width: 992px) {
    .toolbox {
        width: 100%;
    }

    .boxPickUp_DropOff {
        margin: 5px 0 0 0;
    }

    /* .boxSelectPickUp_DropOff {
        margin-top: 10px;
    } */

    .posButton {
        margin: 0;
    }

    .button-card {
        /* margin-top: 10px; */
        /* margin-bottom: 10px; */
        text-align: center;
    }

    .td-pos {
        float: right;
        right: 15px;
    }

    .input-table {
        max-width: 160px;
        width: 100%;
    }

    .box-default {
        width: 100%;
        margin-bottom: 20px
    }

    .td-input {
        padding: 5px;
    }

    .td-people {
        padding: 12px;
    }

    .td-button {
        padding: 6px;
        text-align: center;
    }

    .posButton2 {
        padding-right: 0;
    }

    .text-pos-product-head {
        margin-left: 0;
    }

    /* table */
    .tbody-createBooking {
        border-radius: 0rem 1rem 1rem 0rem;
    }

    .td-productName {
        max-width: 250px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        /* Mozilla, since 1999 */
        white-space: -pre-wrap;
        /* Opera 4-6 */
        white-space: -o-pre-wrap;
        /* Opera 7 */
        height: 140px;
    }


    .th-productName {
        height: 140px;
    }

    .th-travelDate {
        height: 52px;
    }

    .th-adult {
        height: 46px;
    }

    .th-child {
        height: 44px;
    }

    .th-null {
        height: 44px;
    }

    .boxCreateBooking-DatePicker {
        margin-left: 40px;
        margin-right: 40px;
    }

    .box-productlist {
        margin-left: 13px;
    }

    .input-search {
        width: 100%;
    }

}

@media (min-width: 991px) {

    .toolbox {
        width: 210px;
    }

    .posButton {
        text-align: right;
        padding: 27px 15px 15px 0px;
    }

    .box-default {
        width: 100%;
    }

    /* table */
    .table-createBooking {
        overflow: visible;
    }

    .thead-createBooking th:first-child {
        border-radius: 1rem 0rem 0rem 0rem;
    }

    .thead-createBooking th:last-child {
        border-radius: 0rem 1rem 0rem 0rem;
    }

    .tbody-createBooking {
        border-radius: 0rem 0rem 1rem 1rem;
    }


    .tr-createBooking:last-child td:first-child {
        border-bottom: none;
        border-radius: 0rem 0rem 0rem 1rem;
    }

    .tr-createBooking:last-child td:last-child {
        border-bottom: none;
        border-radius: 0rem 0rem 1rem 0rem;
    }

    .td-input {
        width: 150px;
    }

    .td-productName {
        max-width: 300px;
        min-width: 200px;
    }

    .td-product_ref {
        text-align: center;
    }

    .input-search {
        width: 600px;
    }

    .boxPickUp_DropOff {
        margin-top: 7px;
    }
}