html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Helvetica;
    background: #f2f2f2;
}

@media (max-width: 992px) {
    .container-fluid {
        margin: 0;
        padding: 0;
    }
}