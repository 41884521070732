.tag {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	width: 200px;
	padding-left: 27px;
	justify-content: center;
	text-transform: uppercase;
	float: left;
}

.radio-1 {
	width: 193px;
}

.button-holder {
	float: left;
}

/* RADIO */

.regular-radio {
	display: none;
}

.regular-radio + label {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 50px;
	display: inline-block;
	position: relative;
}

.regular-radio:checked + label:after {
	content: ' ';
	width: 12px;
	height: 12px;
	border-radius: 50px;
	position: absolute;
	top: 3px;
	background-color: #99a1a7;
	box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
	text-shadow: 0px;
	left: 3px;
	font-size: 32px;
}

.regular-radio:checked + label {
	background-color: #e9ecee;
	color: #99a1a7;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1), inset 0px 0px 10px rgba(0,0,0,0.1);
}

.regular-radio + label:active, .regular-radio:checked + label:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.big-radio + label {
	padding: 16px;
}

.big-radio:checked + label:after {
	width: 24px;
	height: 24px;
	left: 4px;
	top: 4px;
}

.checkmark {
	position: absolute;
	margin-top: 4px;
	height: 18px;
	width: 18px;
	background-color: black;
}

img {
	max-width: 50%;
	height: 50%;
  }

.img_load {
	width: 300px;
	height: 300px;
	position: absolute;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);;
}

.alert-validate {
	font-family: Arial, Helvetica, sans-serif;
	color: #c80000;
	padding-left: 70px;
}
