.selectMonth {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  padding-left: 10px;
  height: 35px;
  border-radius: 0.4rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #ddd;
  border-collapse: collapse;
  width: 150px;
  background: white;
  margin-top: 5px;
  margin-left: 14px;
}

.dropdown-month {
  position: relative;
  height: 35px;

  &.open>.dropdown-menu {
    display: block;
  }

  .dropdown-toggle {
    display: inline-block;
    padding: 6px 6px;
    margin-bottom: 0;
    font-family: Arial, Helvetica, sans-serif;
    // font-weight: 400;
    width: 100%;
    height: 100%;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: #444444;
  }

  .dropdown-toggle::after {
    display: inline-block;
    position: absolute;
    right: 6px;
    top: 17px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -10px;
    z-index: 1000;
    float: left;
    min-width: 200px;
    height: 350px;
    padding: 5px 0;
    font-size: 0.7rem;
    text-align: center;
    list-style: none;
    color: #444444;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    li {
      cursor: pointer;
      &>p {
        display: block;
        padding: 5px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #444444;
        white-space: nowrap;
        text-decoration: none;
      }
    }
  }

  .dropdown-menu:hover {
    width: auto;
  }
}

@media (max-width: 480px) {
  .selectMonth {
    margin-top: 5px !important;
    margin-left: 0px !important;
  }

}

@media (min-width: 991px) {
  .dropdown-menu {
    margin: 8px 0 0;
    width: 100%;
    height: 200px;
    overflow-y: auto;
  }

}

@media (max-width: 992px) {
  .selectMonth {
    margin-top: 5px;
    margin-left: 0px;
  }

  .dropdown-month .dropdown-menu {
    margin: 8px 10px 0;
    width: 100%;
    height: 250px;
    overflow-y: auto;
    left: -20px;
  }
}