.nav-addcard {
    background-color: #00b4db;
    height: 56px;
    vertical-align: middle;
}

.boxContainer {
    margin-right: 10%;
    margin-left: 10%;
}

.boxHeader {
    margin-left: 10%;
    margin-right: 10%;
    padding: 16px 0px;
}

.boxAlert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-top: 10px;
}

.boxAlert-success {
    color: #1b5e20  ;
    background-color: #dcedc8 ;
    border-color: #c5e1a5  ;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-top: 10px;
}


/* timeline */
.timeLine-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    display: flex;
}

.timeLine {
    list-style: none;
    padding: 0;
    margin: 0 0 16px;
    position: relative;
    width: 100%;
    min-width: 0;
    flex-shrink: 1;
}

.timeLine::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 23px;
    width: 4px;
    background: #444444;
}

.timeLine-title {
    text-align: center;
    display: flex;
    margin-bottom: 10px;
}

.timeLine-item-badge {
    width: 50px;
    flex-shrink: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
}

.timeLine-item-badge-icon {
    min-height: 50px;
    padding: 9px 0;
    border: 1px solid #00b4db;
    background-color: #00b4db;
    border-radius: 100px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    z-index: 5;
    color: white;
}

.timeLine-item-panel {
    border-left: 2px solid #00b4db;
    background: #fff;
    width: 100%;
    min-width: 0;
    flex-shrink: 1;
    position: relative;
    margin-left: 16px;
    border-radius: 0.7rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .25), 0 3px 6px rgba(75, 75, 75, .08);
}

.timeLine-item-panel::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -22px;
    border: 10px solid transparent;
    border-right-color: #00b4db;
}

.block-title {
    font-weight: 700;
    color: inherit;
    margin-left: 66px;
    margin-bottom: 8px;
    text-align: left;
}

.block-detail {
    padding-bottom: 7px;
    border-bottom: 2px solid #00b4db;
    margin-bottom: 10px;
}

.detail-group {
    display: flex;
    content: " ";
    margin-top: 10px;
    align-items: center;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 10px;
}

.detail-group:last-child {
    margin-bottom: 20px;
}

.detail-group-item {
    width: 100%;
}

.detail-group-title {
    min-width: 120px;
    margin-right: 30px;
}

.detail-group-button {
    width: 100%;
}

.detail-error {
    display: flex;
    content: " ";
    align-items: center;
    margin-left: 25%;
    margin-right: 25%;
}

/* icon */
.badge-icon {
    margin-bottom: 5px;
}


/* text */
.text-detailPay {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.text-alert {
    display: block;
    color: #c80000;
    margin-top: 5px;
    font-size: 0.75rem;
}

.label-title,
.label-item-title {
    letter-spacing: 0.5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    color: #444444;
}

.label-item-title {
    display: inline;
}

.label-error-title {
    letter-spacing: 0.5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: right;
    width: 100%;
}

.text-verticalCenter {
    align-self: center;
}

.space-null {
    margin-bottom: 20px;
}

/* link */
.link-addCard {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    letter-spacing: 1px;
}

.link-addCard:hover {
    color: #fff;
}

/* input */
.input-cart {
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0.5rem;
    border: 2px solid #444444;
    background-color: #fff;
    height: 35px;
    padding: 5px;
    width: 100%;
    min-width: 200px;
    letter-spacing: 0.5px;
}

.input-cart:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px skyblue;
}

.textarea-remark {
    min-height: 35px;
    height: 70px;
}

/* table */
.table-addCard {
    box-shadow: none;
}

.thead-addCard {
    color: #444444;
    background-color: white;
}

.thead-addCard th {
    border-bottom: none;
}

.td-addCard {
    border-bottom: none;
}

/* button */
.pos-button {
    float: right;
}


/* display */
@media (max-width: 480px) {
    .boxHeader {
        margin: 0;
    }

    .boxContainer {
        margin: 20px;
    }

    .block-title {
        margin-bottom: 0;
    }

    .detail-group {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .detail-group-title {
        margin-right: 10px;
    }

    .detail-group:last-child {
        margin-bottom: 0;
    }

    .detail-error {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 992px) {
    .detail-group {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media (min-width: 991px) {
  
}