.button-people {
    width: 19px;
    height: 19px;
    font-size: 15px;
    border: none;
    background: white;
    border-radius: 5px;
    color: #444444;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    vertical-align: middle;
}

.box-adult,
.box-child {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 45px;
}

.icon-Adult,
.icon-child {
    display: inline;
    position: absolute;
    color: #444444;
    text-align: center;
}

.icon-Adult {
    font-size: 1.05rem;
    top: 10px;
}

.icon-child {
    top: 15px;
    font-size: 0.8rem;
}

.number {
    display: inline;
    position: absolute;
    text-align: center;
    font-size: 1.2rem;
    left: 30px;
    top: 10px;
    color: #444444;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.add {
    position: absolute;
    left: 55px;
    height: 25px;
}

.subtrack {
    position: absolute;
    left: 55px;
    top: 25px;
    height: 25px;
}

@media (max-width: 992px) {
    .box-adult,
    .box-child {
        position: relative;
        display: inline-flex;
        height: 0px;
    }

    .box-child {
        left: 5px;
    }

    .icon-Adult,
    .icon-child {
        display: inline-block;
        position: relative;
        top: 0px;
        left: 0px;
        margin-right: 10px;
    }

    .icon-child {
        top: 1px;
    }

    .number {
        display: inline;
        position: relative;
        top: -2px;
        left: 0;
        margin-right: 10px;
    }

    .add {
        position: relative;
        display: inline-grid;
        left: 0px;
        margin-right: 5px;
    }

    .subtrack {
        position: relative;
        display: inline-grid;
        display: -moz-inline-grid;
        display: -ms-inline-grid;
        left: 0px;
        top: 0px;
    }

    .boxAdd-Subtract {
        display: inline-flex;
    }
}
