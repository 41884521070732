.box-sales-head1 {
    position: relative;
    float: left;
    margin-top: 15px;
}

.box-sales-head2 {
    position: relative;
    margin-top: 15px;
    float: right;
}

.box-sales-view,
.box-sales-date1,
.box-sales-date2,
.box-sales-button {
    display: block;
    float: left;
}

.box-sales-report {
    position: relative;
    height: auto;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.box-sales-report:hover {
    box-shadow: 0 14px 8px 0 rgba(0, 0, 0, 0.2);
}

.box-sale-report-header {
    position: relative;
    border-radius: 1rem 1rem 0rem 0rem;
    height: 40px;
    display: block;
}

.box-sale-report-body {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px 25px;
    width: 100%;
}

.box-sales-print,
.box-sales-pdf {
    float: right;
    margin-left: 10px;
    z-index: 50;
}

.box-sales-table1 {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.box-sales-table2 {
    margin-bottom: 20px;
}

.box-sales-chart {
    margin-bottom: 40px;
}

.box-sales-button-pdf_print {
    width: 100%;
}

.sales-input-group {
    display: table;
    border-collapse: collapse;
    width: 100%;
    margin-top: 5px;
    background: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.sales-input-group>div {
    display: table-cell;
    border: 1px solid #dddddd;
    vertical-align: middle;
    /* needed for Safari */
}

.sales-input-group-icon {
    color: #444444;
    padding: 0 12px
}

.sales-input-group-area {
    border-radius: 1rem 0rem 1rem 0rem;
    width: 100%;
}

.sales-input-group input {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 0.7rem;
}

/* button */
.button-sales {
    position: relative;
    color: white;
    background: linear-gradient(45deg, #00b4db 30%, #02ccfa 90%);
    box-shadow: 0 4px 5px rgba(0, 146, 165, 0.418);
    border-radius: 0.3rem;
    width: 80px;
    height: 35px;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

.button-sales:after {
    content: "";
    background: whitesmoke;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.button-sales:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}

.button-sales-print,
.button-sales-pdf {
    font-size: 30px;
}

/* text */
.text-sales-head0 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #444444;
}

.text-sales-head0 svg {
    font-size: 1.3rem;
}

.text-sales-head1,
.text-sales-head2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    color: #444444;
}

.text-sales-head3 {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-weight: bold;
    padding-top: 13px;
    font-size: 0.7rem;
    letter-spacing: 1px;
}

.text-sales-head4 {
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    display: block;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

/* icon */
.sales-icon-print,
.sales-icon-pdf {
    color: #00b4db;
}

/* table */
.table-sales {
    justify-items: right;
    border: none;
    box-shadow: none;
    border-radius: 0rem;
}

.thead-sales {
    font-weight: bold;
    color: #fff;
    background: #00b4db;
}

.td-sales {
    padding-left: 20px;
}

.th-sales {
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
}

@media (max-width: 480px) {
    .text-sales-head0 {
        margin-left: 5px !important;
        margin-top: 10px;
    }

    .text-sales-head2 {
        margin-bottom: 5px;
    }

    .box-sales-head1 {
        margin-top: 15px;
        width: 100% !important;
    }

    .box-sales-view {
        margin-left: 5px !important;
        width: 100% !important;
    }

    .box-sales-head2 {
        margin-top: 10px;
        width: 100%;
    }

    .box-sales-date1 {
        max-width: 160px !important;
        margin-left: 5px !important;
        margin-right: 12px;
    }

    .box-sales-date2 {
        max-width: 160px !important;
        margin-left: 10px !important;
    }

    .box-sales-button {
        margin-top: 14px !important;
        margin-left: 0px !important;
        justify-items: center;
        width: 100%;
    }

    .sales-input-group,
    .button-sales {
        width: 100%;
    }

    .text-sales-head3 {
        padding-left: 10px !important;
    }

}

@media (max-width: 992px) {

    .text-sales-head0,
    .box-sales-view {
        margin-left: 10px;
    }

    .box-sales-head1 {
        width: 40%;
    }

    .box-sales-date1,
    .box-sales-date2 {
        display: block;
        max-width: 170px;
        width: 100%;
        position: relative;
        margin-left: 10px;
    }

    .box-sales-button {
        margin-top: 19px;
        margin-left: 10px;
    }

    .sales-input-group {
        width: 100%;
    }

    .box-sales-report {
        margin-top: 15px;
        margin-bottom: 40px;
    }

    .text-sales-head3 {
        padding-left: 15px;
    }

    /* table */
    .tr-sales {
        display: inline-block;
        width: 45%;
    }

    .tbody-sales {
        border-radius: 0rem 1rem 1rem 0rem;
    }
}

@media (min-width: 991px) {

    .text-sales-head0,
    .text-sales-head1 {
        margin-left: 15px;
    }

    .box-sales-date1,
    .box-sales-date2 {
        max-width: 150px;
        margin-left: 15px;
    }

    .box-sales-button {
        margin-top: 19px;
        margin-left: 15px;
    }

    .box-sales-report {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .text-sales-head3 {
        padding-left: 15px;
    }
}